// -----------------------------------------------------------------------------
// This file contains specific styles to the global error page
// -----------------------------------------------------------------------------

.error-page {
    margin: 8rem auto 0 auto;
    max-width: 70rem;
    position: relative;
    padding: 0 1rem;
    color: #fff;
    h1 {
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: inherit;
    }

    h2 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: inherit;
    }

    img {
        max-width: 100%;
        height: auto;
        width: 15rem;
    }

    @media (max-width: 991px) {
        img {
            width: 10rem;
        }
    }

    a {
        display: inline-block;
        padding: 0.7rem 1.5rem;
        font-size: 1rem;
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        margin-top: 1rem;
        transition: all 0.5s;

        &:hover {
            background: #fff;
            color: #000;
        }
    }
}
